import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap } from 'rxjs/operators';
import {  fetchEmployeeData, fetchEmployeeFail, fetchEmployeeSuccess  } from './employee.action';
import { of } from 'rxjs';
import { CrudService } from 'src/app/core/services/crud.service';



@Injectable()
export class EmployeeEffects {
    fetchData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchEmployeeData),
            mergeMap(() =>
                this.CrudService.postData('employee/get' ,{ offset: 1,   page_size: 100000 }).pipe(
                    //tap((x: any ) => console.log("employee data fetch " , x?.data['items'])) ,
                    map((emp : any) => fetchEmployeeSuccess({ employee : emp?.data['items'] ?? [] })),
                    catchError((error) =>
                        //console.log("error 11" , error);
                         of(fetchEmployeeFail({ error }))
                    )
                )
            ),
        ),
    )

  
    constructor(
        private actions$: Actions,
        private CrudService: CrudService
    ) { }

}