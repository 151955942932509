import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })

export class CrudService {
    private baseUrl: string;
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {

        this.headers = new HttpHeaders({
            // 'Access-Control-Allow-Headers': '*',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': '*',
            // 'Content-Type': 'application/json'
        });
        if (!this.baseUrl) {
            this.baseUrl = environment.baseUrlNodeJS;
        }
    }


    /***
     * Get 
     */
    fetchData(url: any): Observable<any[]> {
        return this.http.get<any[]>(url);
    }

    addData(url: any, newData: any): Observable<any[]> {
        return this.http.post<any[]>(url, newData);
    }
    updateData(url: any, updatedData: any): Observable<any[]> {
        return this.http.put<any[]>(url, updatedData);
    }

    deleteData(url: any): Observable<void> {
        return this.http.delete<void>(url);
    }
    getData(url: any, params: any): Observable<any[]> {
        return this.http.get<any[]>(`${this.baseUrl}${url}`, {
            headers: this.headers,
            params: params,
        });
    }
    postData(url: any, params: any): Observable<any[]> {
        return this.http.post<any[]>(`${this.baseUrl}${url}`, params, {
            headers: this.headers
        });
    }

}
