import { Action, createReducer, on } from '@ngrx/store';
import { checkEmpData, fetchEmployeeData, fetchEmployeeFail, fetchEmployeeSuccess }
    from './employee.action';
import { Employee } from './employee.model';



export interface EmployeeState {
    employee: Employee[]
}

export const initialState: EmployeeState = {
    employee: []
};

export const EmployeeReducer = createReducer(
    initialState,
    on(fetchEmployeeSuccess, (state, { employee }) => {
        return { employee: employee };
    }),
    on(checkEmpData, (state) => {
        return { ...state };
    }),
    on(fetchEmployeeFail, (state, { error }) => {
        return { ...state };
    }),

);

export function reducer(state: EmployeeState | undefined, action: Action) {
    return EmployeeReducer(state, action);
}
