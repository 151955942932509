import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { CommonService } from 'src/app/services/api/common-services/common.service';



export const childguardGuard: CanActivateChildFn = (childRoute, state) => {
  let userJson = localStorage.getItem('currentUser');
  if (userJson) {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let currentTime = new Date();
    let loginTime = new Date(user.loginTime);
    if ((currentTime.getTime() - loginTime.getTime()) >  8 * 60 * 60 * 1000) {
      let router = inject(Router);
      let commonService = inject(CommonService);
      commonService.closeAllSockets();
      localStorage.removeItem('currentUser');
      router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
    } else {
      user.loginTime = new Date();
      localStorage.setItem('currentUser', JSON.stringify(user));
      return true;
    }
  }
  return true;
};
