export function setSidebar(currentUser: any): string {
    try {
        let menuList: any = [];
        let permissions = currentUser['permissions'];
        localStorage.setItem('userStatus' , 'online');
        if(!Array.isArray(permissions)){
            localStorage.setItem('defaultRoute', '/dashboard');
            return '/dashboard';
        }
        permissions.sort((a, b) => (a?.order ?? 1) - (b?.order ?? 1));
        let menuCount: number = 3;
        let subMenuCount: number = 1;
        let firstRoute: string = '';
        permissions.forEach((item: any) => {
            if (item['children'] && item['children'].length > 0) {
                let subMenuArr: any = [];
                item['children'].forEach((smenu: any) => {
                    let eachSubMenu = {
                        id: subMenuCount++,
                        label: smenu['label'],
                        parentId: menuCount,
                        link: smenu['redirectUrl'],
                        isChecked: true,
                        isAllCompleted: false,
                        icon: smenu['iconUrl']
                    };
                    subMenuArr.push(eachSubMenu);
                });
                subMenuArr.sort((a: any, b: any) => a.label.localeCompare(b.label))
                let menuArr = {
                    id: menuCount++,
                    label: item['label'],
                    icon: item['iconUrl'],
                    subItems: subMenuArr
                };
                menuList.push(menuArr);
            } else {
                let menuArr = {
                    id: menuCount++,
                    label: item['label'],
                    icon: item['iconUrl'],
                    link: item['redirectUrl']
                };
                menuList.push(menuArr);
            }
        });

        localStorage.setItem('sidebarMenu', JSON.stringify(menuList));

        if (menuList?.length) {
            if (menuList[0]?.subItems?.length) {
                firstRoute = menuList[0]?.subItems[0].link;
            } else {
                firstRoute = menuList[0]?.link;
            }
        } else {
            firstRoute = '/dashboard';
        }
        if (!firstRoute) {
            firstRoute = '/dashboard';
        }
        localStorage.setItem('defaultRoute', firstRoute);
        return firstRoute;

    } catch (error) {
        localStorage.setItem('defaultRoute', '/dashboard');
        return '/dashboard';
    }

}