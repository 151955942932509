const CandidateList = [
    {
        id: '1',
        type: 'Full Time',
        profile: 'assets/images/users/avatar-1.jpg',
        name: 'Steven Franklin',
        position: 'UI/UX Designer',
        location: 'Louisiana',
        price: 38,
        tags: ['Bootstrap', 'HTML', 'CSS'],
    },
    {
        id: '2',
        type: 'Freelance',
        profile: 'assets/images/users/avatar-2.jpg',
        name: 'Dolores Minter',
        position: 'Assistant / Shope Keeper',
        location: 'Hong-Kong',
        price: 25,
        tags: ['Shope', 'Assistant'],
    },
    {
        id: '3',
        type: 'Part Time',
        profile: 'assets/images/users/avatar-3.jpg',
        name: 'Charles Brown',
        position: 'Web Designer',
        location: 'Finlande',
        price: 24,
        tags: ['Bootstrap', 'HTML', 'SASS'],
    },
    {
        id: '4',
        type: 'Internship',
        profile: 'assets/images/users/avatar-4.jpg',
        name: 'Bonnie Harney',
        position: 'Web Developer',
        location: 'France',
        price: 47,
        tags: ['MYSQL', 'PHP', 'Laravel'],
    },
    {
        id: '5',
        type: 'Internship',
        profile: 'assets/images/users/avatar-5.jpg',
        name: 'Stephen Hadley',
        position: 'Graphic Designer',
        location: 'Danemark',
        price: 83,
        tags: ['Figma', 'Adobe XD', 'Sketch'],
    },
    {
        id: '6',
        type: 'Internship',
        profile: 'assets/images/users/avatar-6.jpg',
        name: 'Henry Wells',
        position: 'Executive, HR Operations',
        location: 'Danemark',
        price: 65,
        tags: ['HR', 'Executive', 'Professional'],
    },
    {
        id: '7',
        type: 'Full Time',
        profile: 'assets/images/users/avatar-7.jpg',
        name: 'Adam Miller',
        position: 'Education Training',
        location: 'Colombie',
        price: 40,
        tags: ['Teaching', 'React', 'Training'],
    },
    {
        id: '8',
        type: 'Freelance',
        profile: 'assets/images/users/avatar-8.jpg',
        name: 'Keith Gonzales',
        position: 'Product Manager',
        location: 'Brazil',
        price: 50,
        tags: ['Manager', 'Business', 'Product'],
    },
];

export { CandidateList };
