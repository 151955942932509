import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationCountSubject = new BehaviorSubject<number>(0);
  public notificationCount$ = this.notificationCountSubject.asObservable();

  constructor() {}

  updateNotificationCount(count: number) {
    this.notificationCountSubject.next(count);
  }

  getNotificationCount() {
    return this.notificationCountSubject.getValue();
  }
}
