import { Injectable } from '@angular/core';
import { PbxDiallerService } from 'src/app/core/services/pbx-dialling.service';
import { SocketService } from './socket.service';
import { PesonalChatSocketService } from './personalchat.socket.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public dashboardLeadStatus = "";
  public dashboardVisaType = "";
  public dashboardServiceInterest = "";
  public isDashboardRevisitCount = false;
  public isDashboardFollowUps = false;
  public isRevisit = false
  public isConvertedLeads = false;
  
  constructor(
    private _pbxDiallerService: PbxDiallerService,
    private _socketClient: SocketService,
    private _chatSocket: PesonalChatSocketService
  ){}
  
  public ResetSearches() {
    this.dashboardLeadStatus = "";
    this.dashboardVisaType = "";
    this.dashboardServiceInterest = "";
    this.isDashboardRevisitCount = false;
    this.isDashboardFollowUps = false;
    this.isRevisit = false;
    this.isConvertedLeads = false;
  }
  closeAllSockets(): void {
    this._socketClient.disconnect();
    this._chatSocket.disconnect();
    this._pbxDiallerService.destroyPBXSDK();
  }

}
