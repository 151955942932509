const listData = [
    {
        name: 'Marion Burton',
        id: '#14251',
        title: 'Skote Dashboard UI',
        amount: '$1455',
        date: '10 Oct, 19',
    },
    {
        image: 'assets/images/users/avatar-2.jpg',
        name: 'Francis Witte',
        id: '#14252',
        title: 'Brand logo design',
        amount: '$1024',
        date: '11 Oct, 19',
    },
    {
        image: 'assets/images/users/avatar-7.jpg',
        name: 'Joseph Flint',
        id: '#14253',
        title: 'Landing page Design',
        amount: '$1189',
        date: '12 Oct, 19',
    },
    {
        image: 'assets/images/users/avatar-8.jpg',
        name: 'Larry Nielsen',
        id: '#14254',
        title: 'Redesign - Landing page',
        amount: '$1254',
        date: '12 Oct, 19',
    },
    {
        image: 'assets/images/users/avatar-5.jpg',
        name: 'Mark Evans',
        id: '#14255',
        title: 'Blog Template Design',
        amount: '$1024',
        date: '11 Oct, 19',
    },
    {
        name: 'Timothy Lee',
        id: '#14256',
        title: 'Landing page Design',
        amount: '$1189',
        date: '13 Oct, 19',
    },
    {
        image: 'assets/images/users/avatar-1.jpg',
        name: 'Stanley Bland',
        id: '#14257',
        title: 'Landing page UI',
        amount: '$1148',
        date: '14 Oct, 19',
    },
    {
        name: 'Tommy Wilson',
        id: '#14258',
        title: 'Redesign - Dashboard',
        amount: '$1259',
        date: '15 Oct, 19',
    },
    {
        image: 'assets/images/users/avatar-4.jpg',
        name: 'Louis Brandon',
        id: '#14257',
        title: 'Email Template UI',
        amount: '$1355',
        date: '15 Oct, 19',
    },
];

export { listData };
