import { MenuItem } from './menu.model';


export const MENU: MenuItem[] = [] ;


// done using class static is good 

/*
  [
        {
            id: 1,
            label: 'MENUITEMS.MENU.TEXT',
            isTitle: true
        },
        {
            id: 2,
            label: 'Dashboard',
            icon: 'bx-home-circle',
            link: '/dashboard',
        },
        {
            "id": 12,
            "label": "Lead",  // From the first JSON
            "link": "lead",
            "icon": "bx-bullseye"
        },
        {
            "id": 13,
            "label": "Bpo Lead",
            "link": "/bpo-lead",
            "icon": "bx-headphone"
        },
        {
            "id": 7,
            "label": "Student Management",  // From the first JSON
            "icon": "bx-group",
            "subItems": [
                {
                    "id": 8,
                    "label": "Students",  // From the first JSON
                    "parentId": 7,
                    "link": "/student",
                    "icon": "bx-user"
                },
                {
                    "id": 9,
                    "label": "Explore",
                    "parentId": 7,
                    "link": "/explore",
                    "icon": "bx-search"
                }
            ]
        },
        {
            "id": 11,
            "label": "General Visa",  // From the first JSON
            "link": "/visitorvisa",
            "icon": "bx-globe"
        },
        {
            "id": 14,
            "label": "Applications",  // From the first JSON
            "link": "/application",
            "icon": "bx-file"
        },
        {
            "id": 19,
            "label": "HR Management",  // From the first JSON
            "icon": "bx-id-card",
            "subItems": [
                {
                    "id": 20,
                    "label": "Manage Employee",  // From the first JSON
                    "parentId": 19,
                    "link": "/employee",
                    "icon": "bx-user"
                },
                {
                    "id": 21,
                    "label": "Role & Permissions",  // From the first JSON
                    "parentId": 19,
                    "link": "/roles",
                    "icon": "bx-lock"
                }
            ]
        },
        {
            "id": 22,
            "label": "Telecaller List",
            "link": "/telecaller-list",
            "icon": "bx-phone"
        },
        {
            "id": 26,
            "label": "Reports",  // From the first JSON
            "icon": "bx-bar-chart",
            "subItems": [
                {
                    "id": 27,
                    "label": "Revisit",  // From the first JSON
                    "parentId": 26,
                    "link": "report/revisit-report",
                    "icon": "bx-repeat"
                } , 
                {
                    "id": 179,
                    "label": "Sales Report",  // From the first JSON
                    "parentId": 171,
                    "link": "/reports/sales",
                    "icon": "bx-repeat"
                } ,
                {
                    "id": 180,
                    "label": "Operational Report",  // From the first JSON
                    "parentId": 171,
                    "link": "/reports/operational-report",
                    "icon": "bx-repeat"
                } ,
                {
                    "id": 181,
                    "label": "IVR Report", 
                    "parentId": 171,
                    "link": "/reports/ivr-report",
                    "icon": "bx-repeat"
                }
            ]
        },
        {
            "id": 29,
            "label": "Enquiry",  // From the first JSON
            "link": "/enquiry",
            "icon": "bx-message"
        },
        {
            "id": 10,
            "label": "Package",  // From the first JSON
            "link": "/student/student-package",
            "icon": "bx-package"
        },
        {
            "id": 30,
            "label": "Accounts Section",  // From the first JSON
            "icon": "bx-cog",
            'subItems':
                [{
                    "id": 31,
                    "label": "Master Account",  // From the first JSON
                    "link": "/account",
                    "icon": "bx-key",
                    "parentId": 30,
                },
                {
                    "id": 32,
                    "label": "Branch-Statement",  // From the first JSON
                    "link": "/branchstatement",
                    "icon": "bx-receipt",
                    "parentId": 30,
                },
                {
                    "id": 33,
                    "label": "Daily-Book",  // From the first JSON
                    "link": "daily-book/list",
                    "icon": "bx-book",
                    "parentId": 30,
                },
                {
                    "id": 34,
                    "label": "Coupons",  
                    "link": "/coupons",
                    "icon": "bx-package",
                    "parentId": 30,
                },
                {
                    "id": 35,
                    "label": "Cash Accounts",  
                    "link": "/cash-accounts",
                    "icon": "bx-package",
                    "parentId": 30,
                },
            ]
         },
        {
            "id": 180,
            "label": "Manage Master Data",  // From the first JSON
            "icon": "bx-cog",
            'subItems': [
                {
                    id: 15,
                    label: 'Program Level',
                    parentId: 180,
                    "link": "/setting/program-level",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 16,
                    label: 'Education Level',
                    parentId: 180,
                    "link": "/setting/education-level",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 17,
                    label: 'Discipline',
                    parentId: 180,
                    "link": "/setting/discipline",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 23,
                    label: 'Subdiscipline',
                    parentId: 180,
                    "link": "/setting/sub-discipline",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 24,
                    label: 'Grading Scheme',
                    parentId: 180,
                    "link": "/setting/grading-scheme",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 25,
                    label: 'Country',
                    parentId: 180,
                    "link": "/setting/countries",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 26,
                    label: 'State ',
                    parentId: 180,
                    "link": "/setting/state",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 27,
                    label: 'City ',
                    parentId: 180,
                    "link": "/setting/city",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 28,
                    label: 'Application',
                    parentId: 180,
                    "link": "/setting/application",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 29,
                    label: 'Visa Type',
                    parentId: 180,
                    "link": "/setting/visa-type",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 30,
                    label: 'Social Media',
                    parentId: 180,
                    "link": "/setting/social-media",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 31,
                    label: 'Referral Source',
                    parentId: 180,
                    "link": "/setting/referral-source",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 32,
                    label: 'Service Interest ',
                    parentId: 180,
                   "link": "/setting/services-interest",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 33,
                    label: 'Intake',
                    parentId: 180,
                    "link": "/setting/intake",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 37,
                    label: 'Particular',
                    parentId: 180,
                    "link": "/setting/particular",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 38,
                    label: 'Packages',
                    parentId: 180,
                    "link": "/setting/package",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 4,
                    label : "Institutions & Programs",  
                    parentId : 180,
                    "link": "programs-and-institutions",
                    icon: "bxs-buildings"
                },
                {
                    id: 5,
                    label: "Update Program",
                    parentId: 180,
                    "link": "/update-programs",
                    icon: "bx-edit"
                },
                {
                    "id": 23,
                    "label": "Manage Branches",  // From the first JSON
                    "link": "/branch",
                    "icon": "bx-building",
                    "parentId": 180,
                },
                {
                    "id": 24,
                    "label": "Team",  // From the first JSON
                    "link": "/team",
                    "icon": "bx-group",
                    "parentId": 180,
                },
                {
                    "id": 25,
                    "label": "Department",  // From the first JSON
                    "link": "/department",
                    "icon": "bx-network-chart",
                    "parentId": 180,
                },


                
            ]
        },
        {
            id: 181,
            label: "Manage Company",
            icon: "bx-cog",
            'subItems': [
               
                {
                    id: 82,
                    label: 'Company List',
                    parentId: 181,
                    "link": "/manage-company",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 83,
                    label: 'Manage Services & Features',
                    parentId: 181,
                    "link": "/manage-company/services",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 84,
                    label: 'Manage Plan',
                    parentId: 181,
                    "link": "/manage-company/plans",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 85,
                    label: 'Manage Permissions',
                    parentId: 181,
                    "link": "/manage-company/permissions",
                    isChecked: true,
                    isAllCompleted: false
                },
                {
                    id: 86,
                    label: 'Add Roles',
                    parentId: 181,
                    "link": "/manage-company/addRoles",
                    isChecked: true,
                    isAllCompleted: false
                }

            ]
        },
       
        {
            id: 181,
            label: "Marketing Automation",
            icon: "bx-cog",
            'subItems': [
                {
                    "id": 189,
                    "label": "Template",  // From the first JSON
                    "icon": "bx-message",
                    'subItems': [
                {
                    id: 87,
                    label: 'Email',
                    parentId: 189,
                    "link": "marketing-automation/template/email",
                },

                {
                    id: 88,
                    label: 'Whatsapp',
                    parentId: 189,
                    "link": "marketing-automation/template/whatsapp",
                },
                {
                    id: 89,
                    label: 'SMS',
                    parentId: 189,
                    "link": "marketing-automation/template/sms",
                },
                

            ]
                },
            ]
        },
        
        {
            "id": 190,
            "label": "Lead Manager",  // From the first JSON
            "link": "/lead-manager",
            "icon": "bx-message"
        },

        {
            "id": 191,
            "label": "Task Manager",  // From the first JSON
            icon: "bx-cog",
            'subItems': [

                {
                    "id": 192,
                    "label": "Create Task",  // From the first JSON
                    "icon": "bx-message",
                    "link": "/tasks/create"
            
                },

                {
                    "id": 192,
                    "label": "Task List",  // From the first JSON
                    "icon": "bx-message",
                    "link": "/tasks/list"
            
                },
               
            ]
        },

        {
            "id": 200,
            "label": "My Followups",  // From the first JSON
            "link": "/followups",
            "icon": "bx-search"
        },
        {
            "id": 210,
            "label": "Live Monitoring",  // From the first JSON
            "link": "/live-monitoring",
            "icon": "bx-search"
        },
        {
            "id": 211,
            "label": "Raw Lead",  // From the first JSON
            "link": "/raw-lead",
            "icon": "bx-bullseye"
        },
    ] ;

    
  */
