const ordersData = [
    {
        id: '#SK2540',
        name: 'Neal Matthews',
        date: '2019-10-07',
        total: '$400',
        status: 'Paid',
        payment_icon: 'fab fa-cc-mastercard',
        payment: 'Mastercard',
        index: 1,
    },
    {
        id: '#SK2541',
        name: 'Jamal Burnett',
        date: '2019-10-07',
        total: '$380',
        status: 'Chargeback',
        payment_icon: 'fab fa-cc-visa',
        payment: 'Visa',
        index: 2,
    },
    {
        id: '#SK2542',
        name: 'Juan Mitchell',
        date: '2019-10-06',
        total: '$384',
        status: 'Paid',
        payment_icon: 'fab fa-cc-paypal',
        payment: 'Paypal',
        index: 3,
    },
    {
        id: '#SK2543',
        name: 'Barry Dick',
        date: '2019-10-05',
        total: '$412',
        status: 'Paid',
        payment_icon: 'fab fa-cc-mastercard',
        payment: 'Mastercard',
        index: 4,
    },
    {
        id: '#SK2544',
        name: 'Ronald Taylor',
        date: '2019-10-04',
        total: '$404',
        status: 'Refund',
        payment_icon: 'fab fa-cc-visa',
        payment: 'Visa',
        index: 5,
    },
    {
        id: '#SK2545',
        name: 'Jacob Hunter',
        date: '2019-10-04',
        total: '$392',
        status: 'Paid',
        payment_icon: 'fab fa-cc-paypal',
        payment: 'Paypal',
        index: 6,
    },
    {
        id: '#SK2546',
        name: 'William Cruz',
        date: '2019-10-03',
        total: '$374',
        status: 'Paid',
        payment_icon: 'fas fa-money-bill-alt',
        payment: 'COD',
        index: 7,
    },
    {
        id: '#SK2547',
        name: 'Dustin Moser',
        date: '2019-10-02',
        total: '$350',
        status: 'Paid',
        payment_icon: 'fab fa-cc-mastercard',
        payment: 'Mastercard',
        index: 8,
    },
    {
        id: '#SK2548',
        name: 'Clark Benson',
        date: '2019-10-01',
        total: '$345',
        status: 'Refund',
        payment_icon: 'fab fa-cc-visa',
        payment: 'Visa',
        index: 9,
    },
];

export { ordersData };
