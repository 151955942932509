import { Injectable, signal } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PesonalChatSocketService {
  private socket: Socket;
  employeeId: string;
  currentUser: any;

  constructor() {

  }
  setConnection(): void {
    const currentUserString = localStorage.getItem('currentUser');
    const currentUser: any = currentUserString ? JSON.parse(currentUserString) : null;
    this.currentUser = currentUser;

    //  this.socket = io('http://13.126.7.48:7000', {
    this.socket = io('https://chat.dev.visionway-dev.com', {

      transports: ['websocket']
    });

    this.socket.on('connect', () => {
      this.emit('join-user', { userId: this.currentUser.employeeId, companyId: this.currentUser.companyId });
      this.emit('get-chat-list', { userId: this.currentUser.employeeId, companyId: this.currentUser.companyId });
    });

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection Error:', error);
    });
    this.socket.on('error', (error: any) => {
      console.error('Socket Error:', error);
    });

    this.socket.on('disconnect', () => {
      // console.log('Disconnected from the server 1');
    });
  }
  emit(event: string, data: any): void {
    this.socket.emit(event, data);
  }
  listen(event: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(event, (data) => {
        subscriber.next(data);
      });
      return () => {
        this.socket.off(event);
      };
    });
  }

  disconnect(): void {

    if (this.socket) {
      console.log('s2 destroyed');

      this.socket.disconnect();

    } else {
      console.log('s2 not found ');

    }
  }
}
