const JobGriddata = [
    {
        id: 1,
        image: "assets/images/companies/adobe.svg",
        title: "Magento Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 2,
        image: "assets/images/companies/adobe-photoshop.svg",
        title: "Product Designer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 3,
        image: "assets/images/companies/airbnb.svg",
        title: "Marketing Director",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 4,
        image: "assets/images/companies/amazon.svg",
        title: "Project Manager",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 5,
        image: "assets/images/companies/flutter.svg",
        title: "HTML Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 6,
        image: "assets/images/companies/mailchimp.svg",
        title: "Business Associate",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 7,
        image: "assets/images/companies/line.svg",
        title: "Product Sales Specialist",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 8,
        image: "assets/images/companies/spotify.svg",
        title: "Magento Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 9,
        image: "assets/images/companies/wordpress.svg",
        title: "Magento Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 10,
        image: "assets/images/companies/upwork.svg",
        title: "Magento Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 11,
        image: "assets/images/companies/sass.svg",
        title: "Magento Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },
    {
        id: 12,
        image: "assets/images/companies/reddit.svg",
        title: "Magento Developer",
        year: "0-2",
        company: "Skote Technology Pvt.Ltd",
        location: "California",
        price: "$250 - $800"
    },

];

export { JobGriddata };
